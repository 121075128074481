
/*---==================
    14. Team css 
=================----*/


.single-team-item{
    &:hover{
        & .chef-img{
            & .chef-overlay,
            & .hover-content{
                visibility: visible;
                opacity: 1;
            }
        }
    }
    & .chef-img{
        position: relative;
        z-index: 1;
        overflow: hidden;
        & img{
            width: 100%;
        }
        & .chef-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 19, 23, 0.2);
            visibility: hidden;
            opacity: 0;
            @include transition(.3s);
        }
        & .hover-content{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px;
            visibility: visible;
            opacity: 0;
            @include transition(.4s);
            & h3.title{
                padding: 5px 15px;
                background-color: #fff;
                font-size: 24px;
                line-height: 26px;
                margin-bottom: -1px;
            }
            & p.position{
                padding: 5px 15px;
                display: inline-block;
                background-color: $primary;
                font: 500 14px $opensans;
                color: #fff;
            }
        }
    }
}


.single-team-item-two{
    &:hover{
        & .chef-img{
            & .chef-overlay,
            & .hover-content{
                visibility: visible;
                opacity: 1;
            }
            & .hover-content{
                bottom: 20px;
            }
        }
    }
    & .chef-img{
        position: relative;
        z-index: 1;
        overflow: hidden;
        & img{
            width: 100%;
        }
        & .chef-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 19, 23, 0.2);
            visibility: hidden;
            opacity: 0;
            @include transition(.3s);
        }
        & .hover-content{
            position: absolute;
            bottom: 50px;
            left: 50%;
            @include transform(translateX(-50%));
            min-width: 245px;
            max-width: 245px;
            padding: 20px;
            visibility: visible;
            opacity: 0;
            background-color: #fff;
            padding: 25px;
            @include transition(.4s);
            & h3.title{
                padding: 5px 15px;
                background-color: $heading;
                color: #fff;
                font-size: 24px;
                line-height: 26px;
                margin-bottom: -1px;
            }
            & p.position{
                padding: 5px 15px;
                display: inline-block;
                background-color: $primary;
                font: 500 14px $opensans;
                color: #fff;
                margin-bottom: 10px;
            }
            & ul.social-link{
                & li{
                    & a{
                        width: 35px;
                        height: 35px;
                        border-radius: 5px;
                        background-color: rgba(14, 19, 23, 0.07);
                        @extend %flex-center;
                        font-size: 14px;
                        margin-inline-end: 2px;
                        &:hover{
                            background-color: $primary;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}