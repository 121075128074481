
// Font Family

$forum: 'Forum', cursive;

$opensans: 'Open Sans', sans-serif;

// Colors

$heading: #0E1317;

$text-color: #666666;

$primary: #DF3F00;


// Responsive Variables

$xl: 'only screen and (min-width: 1200px)';
$lp: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lm : '(max-width: 1199px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$xm:'(max-width: 991px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$xss:'(max-width: 575px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';