
/*---======================
    13. Testimonial css 
======================----*/

.testimonial-wrapper-one {
    & .testimonial-slider-one{
        margin-left: -15px;
        margin-right: -15px;
        & .slick-track{
            padding: 10px 0 0 10px;
        }
        & .single-testimonial-one{
            margin-left: 15px;
            margin-right: 15px;
        }
    }  
}

.testimonial-slider-three{
    margin-left: -15px;
    margin-right: -15px;
    @media #{$xs}{
        margin-left: -12px;
        margin-right: -12px;
    }
    & .single-testimonial-three{
        margin-left: 15px;
        margin-right: 15px;
    }
}

.testimonial-arrows{
    display: flex;
    justify-content: flex-end;
    @media #{$xs}{
        justify-content: flex-start;
    }
    @media #{$sm}{
        justify-content: flex-end;
    }
    & .slick-arrow{
        width: 65px;
        height: 65px;
        background-color: $heading;
        @extend %flex-center;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        z-index: 1;
        @include transition(.3s);
        &.prev{
            margin-inline-end: 10px;
        }
        &:hover{
            background-color: $primary;
            color: #fff;
        }
    }
}

.testimonial-one_image-box{
    min-height: 455px;
    @media #{$xm}{
        display: none;
    }
    & .testimonial-img-one{
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    & .testimonial-img-two{
        position: absolute;
        right: 25%;
        bottom: 30%;
    }
    & .testimonial-img-three{
        position: absolute;
        bottom: -20px;
        left: -75px;
        width: 115px;
        height: 125px;
        border-radius: 57.5px;
    }
}

.author-title-thumb{
    align-items: center;
    & .author-thumb{
        flex: 0 0 auto;
        width: 80px;
        height: 80px;
        margin-inline-end: 20px;
        @media #{$xss}{
            margin-inline-end: 15px;
        }
        & img{
            width: 100%;
            border-radius: 50%;
        }
    }
    & .author-title{
        & h3.title{
            line-height: 1;
            margin-bottom: 10px;
        }
        & p.position{
            font-size: 16px;
            line-height: 1;
        }
    }
}

.single-testimonial-one{
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        top: -10px;
        left: -10px;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid rgba(255, 255, 255, 0.07);
        z-index: -1;
    }
    & .testimonial-inner-content{
        background-color: #0E1317;
        padding: 35px 60px 50px;
        border: 1px solid #DF3F00;
        @media #{$xm}{
            padding: 35px 30px 50px;
        }
        & > p{
            font-size: 27px;
            line-height: 45px;
            color: #FFFFFF;
            margin-bottom: 40px;
            @media #{$xm}{
                font-size: 20px;
                line-height: 30px;
            }
        }
        & .author-quote-box{
            @media #{$xm}{
                align-items: center;
            }
            & .quote{
                display: inherit;
                @media #{$xs}{
                    display: none;
                }
                @media #{$sm}{
                    display: inherit;
                }
                & i{
                    font-size: 80px;
                    color: $primary;
                    @media #{$xm}{
                        font-size: 60px;
                    }
                }
            }
            .author-title-thumb{
                & .author-title{
                    & p.position,
                    & h3.title{
                        color: #fff;
                    }
                    & h3.title{
                        @media #{$xm}{
                            font-size: 24px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    } 
}


.single-testimonial-two{
    & .testimonial-inner-content{
        & > p{
            color: #fff;
            font: 400 35px $forum;
            line-height: 46px;
            margin-bottom: 32px;
            @media #{$xs}{
                font-size: 27px;
                line-height: 35px;
            }
        }
        & .author-ratings-box{
            align-items: center;
            & .author-title-thumb{
                & .author-title{
                    & h3{
                        color: #fff;
                        @media #{$xs}{
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                    & p{
                        color: #fff;
                    }
                }
            }
            & .ratings-box{
                & ul.ratings{
                    background-color: #fff;
                    padding: 13px 20px 16px;
                    line-height: 1;
                    border-radius: 5px;
                    @media #{$xs}{
                        padding: 13px 15px 16px;
                    }
                    & li{
                        font-size: 20px;
                        @media #{$xs}{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.single-testimonial-three{
    padding: 30px 35px 20px;
    border: 1px solid rgba(14, 19, 23, 0.1);
    & .testimonial-inner-content{
        text-align: center;
        & .rating-quote-box{
            margin-bottom: 20px;
            & .quote{
                i{
                    font-size: 42px;
                    color: $primary;
                }
            }
        }
        & > h3{
            font-size: 40px;
            line-height: 38px;
            margin-bottom: 35px;
        }
        & > p{
            margin-bottom: 45px;
        }
        & .author-title-thumb{
            display: flex;
            justify-content: center;
            padding-top: 20px;
            border-top: 1px solid rgba(14, 19, 23, 0.1);
            text-align: left;
            & .author-thumb{
                width: 60px;
                height: 60px;
            }
            & .author-title{
                & h3.title{
                    font-size: 24px;
                }
                & .position{
                    font-size: 16px;
                }
            }
        }
    }
}


.testimonial-content-box{
    margin-top: -10px;
    padding: 0 40px;
    @media #{$xm}{
        padding: 0;
    }
    & .testimonial-dots{
        margin-top: 60px;
        & ul.slick-dots{
            text-align: left;
            & li{
                &:after{
                    background-color: #fff;
                }
                &.slick-active{
                    border-color: #fff;
                }
            }
        }
    }
}